#menu_checkbox {
  opacity: 0;
}

label {
  height: 30px;
  width: 30px;
}

label div {
  background-color: #b1745f;
  border-radius: 2px;
  height: 6px;
  margin-bottom: 6px;
  position: relative;
  top: 0;
  transition: transform 0.3s ease, top 0.3s ease, width 0.3s ease,
    right 0.3s ease;
}

label div:first-child {
  transform-origin: 0;
}

label div:last-child {
  margin-bottom: 0;
  transform-origin: 30px;
}

label div:nth-child(2) {
  right: 0;
  width: 30px;
}

#menu_checkbox:checked + label div:first-child {
  top: -3px;
  transform: rotate(45deg);
}

#menu_checkbox:checked + label div:last-child {
  top: 3px;
  transform: rotate(45deg);
}

#menu_checkbox:checked + label div:nth-child(2) {
  right: 6px;
  top: 0;
  transform: rotate(-45deg);
  width: 43px;
}

@keyframes slideRight {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.animate-slide-right {
  animation: slideRight 0.8s ease-in-out;
}

@keyframes slideLeft {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.animate-slide-left {
  animation: slideLeft 0.8s ease-in-out;
}
