@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-collapse-header {
  display: flex !important;
  align-items: center !important;
  background: white !important;
}

.ant-collapse .ant-collapse-content {
  background-color: rgba(255, 255, 255, 0.5);
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 11px !important;
}

.ant-collapse > .ant-collapse-item,
.ant-collapse {
  border: none !important;
}

#text {
  text-shadow: rgba(0, 0, 0, 0.25) 0 4px 4px;
}

.ant-carousel .slick-dots li {
  background-color: gray;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #444444 !important;
}
